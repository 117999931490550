import { useContext } from 'react'

import type { IUserContext } from '~/contexts/userContext'
import { UserContext } from '~/contexts/userContext'

export type UseUserContextType = IUserContext

export const useUserContext = (): UseUserContextType => {
  const { userData } = useContext(UserContext)

  return {
    userData,
  }
}
